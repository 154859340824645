import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { FC, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FCWithChildren } from '../../types/FCWithChildren';
import LanguageUtils from '../../utils/LanguageUtils';
import ActionTypes, { ActionTypeNames, ActionTypesInfo } from '../form/ActionTypes';
import DataRow from '../shared/data-grid/DataRow';
import ClosedEyeIcon from '../shared/icon/ClosedEyeIcon';
import CopyIcon from '../shared/icon/CopyIcon';
import DeleteIcon from '../shared/icon/DeleteIcon';
import UsersGroupIcon from '../shared/icon/UsersGroupIcon';
import { Heading, HeadingSize } from '../shared/text/Heading';
import Tooltip from '../shared/Tooltip';
import { Action, BuilderDroppableTypes, EditorType, FormBuilderForm, Section } from './FormBuilderTypes';
import { CSS } from '@dnd-kit/utilities';
import { useDndContext } from '@dnd-kit/core';
import DragHandleIcon from '../shared/icon/DragHandleIcon';
import ConfirmationModal from '../shared/modal/variants/ConfirmationModal';
import { ModalContext } from '../../contexts/ModalContext';
import { interpolateActionData } from '../../utils/interpolation/ActionDataInterpolator';
import { plainText } from '../../utils/RichTextUtils';

type FormEditorProps = {
  formConfig: FormBuilderForm;
  sections: Section[];
  selectedEditorId: string;
  invalidStates: Record<string, string[]>;
  actionTypes: Record<string, ActionTypesInfo>;
  removeSection: (sectionId: string) => void;
  cloneAction: (section: Section, action: Action) => void;
  cloneSection: (section: Section) => void;
  removeAction: () => void;
  setSelectedEditor: (actionId: string, editorType: EditorType) => void;
};

const FormBuilderEditor: FC<FormEditorProps> = (props) => {
  const { sections } = props;

  const sectionIds = useMemo(() => sections.map((x) => x.id), [sections]);

  const stepsToRender = useMemo(() => {
    return sections.map((step, i) => {
      const currentStepNumber = `${i + 1}.`;
      step.number = currentStepNumber;

      const numberingActions = step?.actions.filter((x) => x.data?.useDocumentNumbering);

      step?.actions.forEach((action) => {
        if (action.data?.useDocumentNumbering) {
          action.number = `${currentStepNumber}${numberingActions.indexOf(action) + 1}.`;
        } else {
          action.number = '';
        }
      });

      return step;
    });
  }, [sections]);

  return (
    <div className="select-none" data-cy="form-editor">
      <div data-cy="draggable-area">
        <SortableContext items={sectionIds} strategy={verticalListSortingStrategy}>
          {stepsToRender.map((section, i) => (
            <DraggableSection key={section.id} index={i} section={section} {...props} />
          ))}
        </SortableContext>
      </div>
    </div>
  );
};

export default FormBuilderEditor;

const DraggableSection: FCWithChildren<FormEditorProps & { index: number; section: Section }> = memo(function DraggableSectionMemoized(props) {
  const { sections, selectedEditorId, removeSection, setSelectedEditor, invalidStates, cloneSection, index, section } = props;

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: section.id,
    data: { type: BuilderDroppableTypes.Section, item: section },
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const { t, i18n } = useTranslation('form-builder');

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  return (
    <div
      ref={setNodeRef}
      data-cy={`section-${index}`}
      style={style}
      className={`bg-gray-5 relative mb-4 flex min-h-8 items-stretch rounded-[10px] outline-none`}
    >
      <div data-cy="section-drag-handle" className="flex cursor-grab flex-col justify-start" {...listeners} {...attributes}>
        <DragHandleIcon data-cy="drag-icon" className="text-gray-2 mx-4 mt-6 h-4 w-4 flex-shrink-0" />
      </div>
      <div className="flex-grow pb-4 pr-4">
        <div className="flex items-center py-4" data-cy="section-heading">
          {section.isPublic && <UsersGroupIcon className="mr-2 w-6" />}
          <Heading
            onClick={() => setSelectedEditor(section.id, EditorType.SECTION)}
            data-cy="section-header"
            size={HeadingSize.H4}
            className="flex flex-grow cursor-pointer items-center gap-4"
            textColor={selectedEditorId === section.id ? 'text-primary-2' : 'text-primary-1'}
          >
            {`${section.number} `}
            {LanguageUtils.getTranslation('title', section.translations, i18n.language) ? (
              LanguageUtils.getTranslation('title', section.translations, i18n.language)
            ) : (
              <span className="text-gray-3">{t('untitled-section')}</span>
            )}
            {section.isDraft && <ClosedEyeIcon className="text-primary-1 w-6" />}
            {!!invalidStates[section.id]?.length && (
              <span data-cy="invalid-state-icon" title={t('error-states.section-invalid')} className="text-semantic-3 font-medium">
                ⚠
              </span>
            )}
          </Heading>
          {selectedEditorId === section.id && (
            <Tooltip text={'Duplicate'}>
              {(tooltip) => (
                <div {...tooltip}>
                  <CopyIcon className="w-6" onClick={() => cloneSection(section)} />
                </div>
              )}
            </Tooltip>
          )}
          {sections.length > 1 && (
            <DeleteIcon
              data-cy="remove-section"
              className="h-6 w-6 flex-shrink-0"
              onClick={(e) => {
                if (e.shiftKey && e.ctrlKey) {
                  removeSection(section.id);
                } else {
                  setShowDeleteConfirm(true);
                }
              }}
            />
          )}
        </div>

        <div>
          <SectionActionsWrapper {...props} />
        </div>
      </div>
      <ModalContext.Provider value={{ open: showDeleteConfirm }}>
        <ConfirmationModal
          title={t(`delete-step-modal.title`)}
          description={t(`delete-step-modal.description`)}
          confirmText={t(`delete-step-modal.confirm-text`)}
          onCancel={() => setShowDeleteConfirm(false)}
          onConfirm={() => removeSection(section.id)}
          alt
        />
      </ModalContext.Provider>
    </div>
  );
});

const SectionActionsWrapper: FC<FormEditorProps & { index: number; section: Section }> = memo(function SectionActionsWrapperMemoized(props) {
  const { section } = props;

  const { t } = useTranslation('form-builder');
  // const { setNodeRef } = useDroppable({ id: `section-droppable-${section.id}`, data: { type: BuilderDroppableTypes.Section, item: section } });

  const actionIds = useMemo(() => section.actions.map((x) => x.id), [section.actions]);

  return (
    <div data-cy="section-draggable-area">
      {/* <div ref={setNodeRef}> */}
      {!section.actions.length && (
        <div className="italic" data-cy="drop-here-hint">
          {t('action-properties.common.drop-hint')}
        </div>
      )}
      <SortableContext items={actionIds} strategy={verticalListSortingStrategy}>
        {section.actions.map((action) => (
          <DraggableAction key={action.id} {...props} action={action} />
        ))}
      </SortableContext>
      {/* </div>s */}
    </div>
  );
});

const DraggableAction: FC<FormEditorProps & { section: Section; action: Action }> = memo(function DraggableActionMemoized(props) {
  const { formConfig, selectedEditorId, removeAction, setSelectedEditor, invalidStates, cloneAction, section, action, actionTypes } = props;
  const { i18n } = useTranslation();

  const title = ActionTypes[action.type].actionTitle;
  const actionName = title && title(action, i18n.language);
  const actionInvalidStates = invalidStates[action.id];

  const { t } = useTranslation('form-builder');

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  return (
    <DataRow
      data-cy={`action-${action.number}`}
      selected={action.id === selectedEditorId}
      onClick={() => setSelectedEditor(action.id, EditorType.ACTION)}
      isSortingDisabled={false}
      sortableId={action.id}
      sortableData={{ type: BuilderDroppableTypes.Action, item: props.action, actionTypes }}
    >
      <div className="flex h-full flex-grow justify-between">
        <div>
          <span className="font-medium" data-cy="action-name">
            {action.number} {t(`action-types.${action.type}`)}
          </span>
          {actionName && (
            <span data-cy="action-given-name">
              {' '}
              - {formConfig.placeholders && interpolateActionData(plainText(actionName), formConfig.placeholders)}
              {!formConfig.placeholders && actionName}
            </span>
          )}{' '}
          {!!actionInvalidStates?.length && (
            <Tooltip
              text={actionInvalidStates.map((x, i) => (
                <div key={i}>- {x}</div>
              ))}
            >
              {(tooltip) => (
                <span {...tooltip} data-cy="invalid-state-icon" className="text-semantic-3 font-medium">
                  ⚠
                </span>
              )}
            </Tooltip>
          )}
        </div>
        {action.id === selectedEditorId && (
          <div className="flex gap-1">
            <Tooltip text={t('action-properties.common.duplicate')}>
              {(tooltip) => (
                <div {...tooltip}>
                  <CopyIcon className="w-6" onClick={() => cloneAction(section, action)} data-cy="clone-action" />
                </div>
              )}
            </Tooltip>
            <Tooltip text={t('action-properties.common.remove')}>
              {(tooltip) => (
                <div {...tooltip}>
                  <DeleteIcon
                    className="h-6 w-6 flex-shrink-0"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (e.shiftKey && e.ctrlKey) {
                        removeAction();
                      } else {
                        setShowDeleteConfirm(true);
                      }
                    }}
                    data-cy="remove-action"
                  />
                </div>
              )}
            </Tooltip>
          </div>
        )}
      </div>
      <ModalContext.Provider value={{ open: showDeleteConfirm }}>
        <ConfirmationModal
          title={t(`delete-action-modal.title`)}
          description={t(`delete-action-modal.description`)}
          confirmText={t(`delete-action-modal.confirm-text`)}
          onCancel={() => setShowDeleteConfirm(false)}
          onConfirm={removeAction}
          alt
        />
      </ModalContext.Provider>
    </DataRow>
  );
});

export const FormBuilderDragOverlay: FC = () => {
  const { activeNodeRect, active } = useDndContext();
  const size = { width: activeNodeRect?.width, height: activeNodeRect?.height };

  const { t, i18n } = useTranslation('form-builder');

  const text = useMemo(() => {
    switch (active?.data.current?.type) {
      case BuilderDroppableTypes.Sidebar:
        return active.data.current.item.name;
      case BuilderDroppableTypes.Section:
        return <span className="font-medium">{LanguageUtils.getTranslation('title', active.data.current.item.translations, i18n.language)}</span>;
      case BuilderDroppableTypes.Action: {
        const actionTitleFn = active.data.current.actionTypes[active.data.current.item.type].actionTitle;
        const question = actionTitleFn ? actionTitleFn(active.data.current.item, i18n.language) : '';
        return (
          <>
            <span className="font-medium">{active.data.current && t(`action-types.${active.data.current.item.type as ActionTypeNames}`)}</span>
            &nbsp;
            {question ? ` - ${question}` : ''}
          </>
        );
      }
      default:
        return '';
    }
  }, [active?.data, i18n.language, t]);

  return (
    <div
      className="border-primary-2 flex min-h-[16px] cursor-grabbing items-center justify-center rounded-xl border-2 bg-white bg-opacity-90"
      style={{ ...size }}
    >
      {text}
    </div>
  );
};
