/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Option } from '../Option';
import DropdownSelect from '../shared/form-control/DropdownSelect';
import ConditionCombiner from './ConditionCombiner';
import { FormBuilderForm, StepTrigger } from './FormBuilderTypes';

type StepTriggerProps = {
  form: FormBuilderForm;
  availableSteps: Option<string, string>[];
  selectedStep: Option<string, string>;
  stepTrigger: StepTrigger;
  onConditionUpdated: (stepId: string, condition: string) => void;
  lockToSectionId?: string;
};

const StepTriggerEditor: FC<StepTriggerProps> = (props) => {
  const { onConditionUpdated, form, stepTrigger, availableSteps, selectedStep, lockToSectionId } = props;
  const [selectedTriggerStep, setSelectedTriggerStep] = useState<Option<string, string | number>>(selectedStep);
  const { t } = useTranslation('form-builder');

  useEffect(() => {
    setSelectedTriggerStep(selectedStep);
  }, [selectedStep]);

  const onTriggerStepChange = (step: Option<string, string | number>) => {
    onConditionUpdated(step.id, stepTrigger.condition);
    setSelectedTriggerStep(step);
  };

  return (
    <div>
      <div className="mt-4 flex items-center gap-2">
        <div>
          <span className="font-medium">{t('form-section-properties.tigger-editor-title')}</span>
        </div>
        <div>
          <DropdownSelect
            data-cy="trigger-section"
            options={availableSteps}
            placeholder={t('form-section-properties.tigger-editor-placeholder')}
            onChange={onTriggerStepChange}
            value={selectedTriggerStep}
          />
        </div>
      </div>
      <ConditionCombiner
        form={form}
        disabled={selectedTriggerStep.id === 'none'}
        action={undefined as any}
        actionCondition={stepTrigger.condition}
        onConditionUpdated={(condition) => {
          onConditionUpdated(selectedTriggerStep.id, condition);
        }}
        lockToSectionId={lockToSectionId}
      />
    </div>
  );
};

export default StepTriggerEditor;
