/* eslint-disable @typescript-eslint/no-explicit-any */
import { createElement, FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ActionTypes from '../form/ActionTypes';
import { ActionPropertiesProps, EditorProps } from './FormBuilderTypes';
import { Translations } from '../../models/Translation';

const editors: Record<keyof typeof ActionTypes, FC<EditorProps>> = Object.assign(
  {},
  ...Object.entries(ActionTypes).map(([key, info]) => ({
    [key]: info.builderEditor,
  })),
);

const FormActionProperties: FC<ActionPropertiesProps> = (props) => {
  const { action, patchAction, form, invalidStates, patchForm, referencedForms, builderBaseUrl } = props;

  const { t } = useTranslation('form-builder');

  const patchTranslations = useCallback(
    (translations: Translations) => {
      patchAction({ translations });
    },
    [patchAction],
  );

  if (!action) {
    return null;
  }

  return (
    <div data-cy="action-properties">
      {editors[action.type] ? (
        createElement(editors[action.type], {
          key: action.id,
          action,
          patchAction,
          patchData: (data: any) => patchAction({ data: Object.assign({}, action?.data ?? {}, data) }),
          form,
          invalidStates,
          patchForm,
          referencedForms,
          builderBaseUrl,
          patchTranslations,
        })
      ) : (
        <div className="italic" data-cy="no-properties-warning">
          {t('action-properties.common.properties-empty', { type: t(`action-types.${action.type}`) })}
        </div>
      )}
    </div>
  );
};

export default FormActionProperties;
