import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionTypeNames, ActionTypesInfo, useActionTypeBuilderIdGenerator } from '../form/ActionTypes';
import { ActionTypeListItem } from './ActionTypeListItem';
import { Action } from './FormBuilderTypes';

type ActionTypeListProps = {
  actionTypes: Record<ActionTypeNames, ActionTypesInfo>;
};

const ActionTypesList: FC<ActionTypeListProps> = (props) => {
  const { actionTypes: actionTypeInfos } = props;
  const { t } = useTranslation('form-builder');
  const { getId } = useActionTypeBuilderIdGenerator();

  const actionTypes: Action[] = useMemo(
    () =>
      Object.entries(actionTypeInfos)
        .map(([type, info]) => ({
          id: getId(type) || '',
          type: type,
          name: t(`action-types.${type}`),
          hidden: info.hidden,
        }))
        .filter((x) => !x.hidden)
        .sort((a, b) => a.name.localeCompare(b.name)),
    [actionTypeInfos, getId, t],
  );

  return (
    <div className="bg-gray-5 h-full select-none" data-cy="activity-list">
      {actionTypes.map((actionType) => (
        <ActionTypeListItem
          key={actionType.id}
          id={actionType.id}
          type={actionType.type as string}
          name={t(`action-types.${actionType.type}`)}
        ></ActionTypeListItem>
      ))}
    </div>
  );
};

export default ActionTypesList;
