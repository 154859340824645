import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormType, formTypeOptions } from '../../models/FormTypes';
import { Option } from '../Option';
import Checkbox, { SliderSize } from '../shared/form-control/Checkbox';
import DropdownSelect from '../shared/form-control/DropdownSelect';
import IconPicker from '../shared/form-control/IconPicker';
import { Input } from '../shared/form-control/Input';
import { PlatformIcons } from '../shared/icon/IconRender';
import { FormPropertiesProps, PlaceholderTarget } from './FormBuilderTypes';
import Accordion from '../shared/accordion/Accordion';
import ReviewGoal from '../shared/periodic-review/ReviewGoal';
import ReviewConfig from '../shared/periodic-review/ReviewConfig';
import ReviewCheckList from '../shared/periodic-review/ReviewCheckList';
import { PeriodicReviewContext } from '../../contexts/PeriodicReviewContext';
import { PeriodicReviewConfig } from '../../models/Form';
import { v4 as uuid } from 'uuid';
import ConfirmationModal from '../shared/modal/variants/ConfirmationModal';
import { ModalContext } from '../../contexts/ModalContext';

import TranslatableInput from '../shared/form-control/TranslatableInput';
import { EmptyPeriodicReviewConfig } from '../shared/periodic-review/ReviewWizard';
import TemplateModuleService from '../../services/TemplateModuleService';
import { ModuleTemplate, ModuleType } from '../../models/Module';
import ClientTemplateModuleService from '../../services/ClientTemplateModuleService';
import { useRecoilValue } from 'recoil';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import ManageClassesWizard, { ManageClassesWizardStep, manageClassesWizardSteps } from '../documents/manage-classes/ManageClassWizard';
import User from '../../models/User';
import ClientService from '../../services/ClientService';
import PdfPreferences from '../form/DownloadPdfPreferences';
import { DefaultDownloadPdfPreferences } from '../../models/DownloadPdfPreferences';
import Tooltip from '../shared/Tooltip';
import { useParams } from 'react-router';
import LanguageUtils from '../../utils/LanguageUtils';
import PlaceholderTextBox from '../shared/placeholder/PlaceholderTextBox';

const FormProperties: FC<FormPropertiesProps> = (props) => {
  const { form, patchFormConfig, loadedType, originalForm, referencedForms } = props;
  const {
    code,
    iconCode,
    type,
    requiresApproval = false,
    requiresValidation = false,
    periodicReviewConfig,
    translations,
    templateModuleId,
    downloadPdfPreferences,
    requiresApprovalSignature,
    requiresValidationSignature,
    requiresOwnerAcknowledgement,
  } = form;

  const currentClient = useRecoilValue(currentClientAtom);
  const { t } = useTranslation(['form-builder', 'periodic-review', 'organisation']);
  const [moduleTemplates, setModuleTemplates] = useState<ModuleTemplate[]>([]);
  const [showAddClassModal, setShowAddClassModal] = useState(false);
  const [activeManageWizardStep, setActiveManageWizardStep] = useState<ManageClassesWizardStep>(manageClassesWizardSteps[3]);
  const [clientUsers, setClientUsers] = useState<User[]>([]);
  const [loadingModules, setLoadingModules] = useState(false);

  const { templateFormId } = useParams<{
    templateFormId: string | undefined;
  }>();

  const loadClasses = useCallback(() => {
    if (type) {
      const moduleType = type === FormType.Document ? ModuleType.Document : ModuleType.Default;
      setLoadingModules(true);
      const getTemplatesPromise = currentClient
        ? new ClientTemplateModuleService(currentClient.id).getTemplates(false, true, [moduleType])
        : TemplateModuleService.getTemplates(false, undefined, [moduleType]);

      return getTemplatesPromise
        .then((res) => setModuleTemplates(currentClient ? res.data.filter((x) => x.isOwnedByClient) : res.data))
        .finally(() => setLoadingModules(false));
    }
  }, [currentClient, type]);

  const onClassCreated = useCallback(
    (moduleTemplate: ModuleTemplate) => {
      setShowAddClassModal(false);
      setActiveManageWizardStep(manageClassesWizardSteps[3]);
      loadClasses()?.then(() => {
        patchFormConfig({
          templateModuleId: moduleTemplate.id,
          templateModuleSectionId: moduleTemplate.sections[0].id,
        });
      });
    },
    [loadClasses, patchFormConfig],
  );

  useEffect(() => {
    loadClasses();
  }, [loadClasses]);

  const getUsers = useCallback(() => {
    if (currentClient) {
      ClientService.getUsers().then((res) => {
        setClientUsers(res);
      });
    }
  }, [currentClient]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const moduleTemplateOptions = useMemo(() => {
    return moduleTemplates.map((x) => ({ id: x.id, text: LanguageUtils.getTranslation('name', x.translations), value: x.id }));
  }, [moduleTemplates]);

  const moduleTemplateOption = useMemo(() => {
    if (templateModuleId === undefined || templateModuleId === null) {
      return {
        id: '',
        value: '',
        text: '',
      };
    }
    const moduleTemplate = moduleTemplates.find((template) => template.id === templateModuleId);
    return {
      id: moduleTemplate?.id || '',
      value: moduleTemplate?.id || '',
      text: LanguageUtils.getTranslation('name', moduleTemplate?.translations ?? {}),
    };
  }, [moduleTemplates, templateModuleId]);

  const formTypeList = formTypeOptions(t);

  const formTypeListOptions = useMemo(() => {
    return formTypeList.filter((x) => x.value !== FormType.Resource);
  }, [formTypeList]);

  const iconText = useMemo(
    () =>
      iconCode
        ?.replace(/Icon$/, '')
        .match(/[A-Z][a-z]+/g)
        ?.join(' ') || '',
    [iconCode],
  );

  const formTypeOption = useMemo(() => {
    if (type === undefined || type === null) {
      return {
        id: '',
        value: '',
        text: '',
      };
    }
    const formType = formTypeList.find((formType) => formType.value === type) as Option<string, string | number>;
    return {
      id: formType.id,
      value: formType.value,
      text: formTypeList.find((type) => type.value === formType.value)?.text as string,
    };
  }, [formTypeList, type]);

  const hasPublicSections = useMemo(() => {
    return form.sections.some((x) => x.isPublic);
  }, [form.sections]);

  const [showPeriodicReview, setShowPeriodicReview] = useState(!!periodicReviewConfig);

  const [showPeriodicReviewCheckList, setShowPeriodicReviewCheckList] = useState(
    periodicReviewConfig?.checkList && periodicReviewConfig.checkList?.length > 0,
  );

  const [showRemoveReviewModal, setShowRemoveReviewModal] = useState(false);

  const [showRemoveReviewCheckListModal, setShowRemoveReviewCheckListModal] = useState(false);

  const togglePeriodicReview = useCallback(
    (value: boolean) => {
      if (value) {
        patchFormConfig({ periodicReviewConfig: EmptyPeriodicReviewConfig });
        setShowPeriodicReview(value);
      } else {
        setShowRemoveReviewModal(true);
      }
    },
    [patchFormConfig],
  );

  const removePeriodicReview = useCallback(() => {
    setShowPeriodicReview(false);
    setShowRemoveReviewModal(false);
    setShowPeriodicReviewCheckList(false);
    patchFormConfig({ periodicReviewConfig: null });
  }, [patchFormConfig]);

  const removePeriodicReviewCheckList = useCallback(() => {
    setShowPeriodicReviewCheckList(false);
    setShowRemoveReviewCheckListModal(false);
    patchFormConfig({
      periodicReviewConfig: { ...(periodicReviewConfig || EmptyPeriodicReviewConfig), checkList: [] },
    });
  }, [patchFormConfig, periodicReviewConfig]);

  const onPeriodicReviewChange = useCallback(
    (value: PeriodicReviewConfig) => {
      if (!value.checkList?.length) {
        setShowPeriodicReviewCheckList(false);
      }
      patchFormConfig({ periodicReviewConfig: value });
    },
    [patchFormConfig],
  );

  const togglePeriodicReviewChecklist = useCallback(
    (value: boolean) => {
      if (value) {
        setShowPeriodicReviewCheckList(value);
        patchFormConfig({
          periodicReviewConfig: {
            ...(periodicReviewConfig || EmptyPeriodicReviewConfig),
            checkList: [...(periodicReviewConfig?.checkList || []), { id: uuid(), translations: {} }],
          },
        });
      } else if (periodicReviewConfig?.checkList?.length) {
        setShowRemoveReviewCheckListModal(true);
      }
    },
    [patchFormConfig, periodicReviewConfig],
  );

  const onDownloadPdfPreferencesChange = useCallback(
    (key: string, value: boolean) => {
      patchFormConfig({ downloadPdfPreferences: { ...(downloadPdfPreferences || DefaultDownloadPdfPreferences), [key]: value } });
    },
    [downloadPdfPreferences, patchFormConfig],
  );

  useEffect(() => {
    if (!requiresApproval && requiresApprovalSignature) {
      patchFormConfig({ requiresApprovalSignature: false });
    }
  }, [patchFormConfig, requiresApproval, requiresApprovalSignature]);

  useEffect(() => {
    if (!requiresValidation && requiresValidationSignature) {
      patchFormConfig({ requiresValidationSignature: false });
    }
  }, [patchFormConfig, requiresValidation, requiresValidationSignature]);

  useEffect(() => {
    if (!requiresValidation && !requiresApproval && requiresOwnerAcknowledgement) {
      patchFormConfig({ requiresOwnerAcknowledgement: false });
    }
  }, [patchFormConfig, requiresValidation, requiresOwnerAcknowledgement, requiresApproval]);

  return (
    <div data-cy="form-properties">
      <Accordion active title={<div className="font-medium">{t('form-builder:form-properties.heading')}</div>}>
        <TranslatableInput
          data-cy="form-title"
          label={t('form-builder:form-properties.form-title')}
          placeholder={t('form-builder:form-properties.form-title')}
          autoFocus
          maxLength={100}
          translationKey="title"
          translations={translations}
          onTranslationsChange={(translations) => patchFormConfig({ translations })}
        />

        <Input
          data-cy="form-code"
          label={t('form-builder:form-properties.form-code')}
          placeholder={t('form-builder:form-properties.form-code')}
          value={code || ''}
          onChange={(e) => patchFormConfig({ code: e.target.value })}
          maxLength={100}
        />

        <PlaceholderTextBox
          form={form}
          referencedForms={referencedForms}
          action={{ targetId: form.id, target: PlaceholderTarget.Form, translations: translations || {} }}
          singleLine
          data-cy="predefined-title"
          inputPlaceholder={t('form-builder:form-properties.predefined-title-label')}
          label={t('form-builder:form-properties.predefined-title-placeholder')}
          onPlaceholdersChange={(placeholders) => {
            patchFormConfig({ placeholders });
          }}
          enableLanguageToggle
          enableRichText={false}
          translationKey={'predefinedTitle'}
          translations={translations ?? {}}
          onTranslationsChange={(translations) => patchFormConfig({ translations })}
        />

        <div className="text-dpm-12 mt-4" data-cy="en-form-icon">
          {t('form-builder:form-properties.form-icon')}
        </div>
        <IconPicker
          data-cy="form-icon"
          options={Object.values(PlatformIcons).map((iconValue) => ({ id: iconValue, text: iconValue, value: iconValue }))}
          placeholder={t('form-builder:form-properties.form-icon')}
          value={{ id: iconCode, text: iconText, value: iconCode }}
          onChange={(option) => patchFormConfig({ iconCode: option.value.toString() })}
        />
        <div className="text-dpm-12 mt-4">{t('form-builder:form-properties.form-type')}</div>
        <DropdownSelect
          data-cy="form-type"
          disabled={loadedType !== undefined && loadedType !== null}
          options={formTypeListOptions}
          value={formTypeOption}
          onChange={(option) =>
            patchFormConfig({
              type: option.value as (typeof FormType)[keyof typeof FormType],
              templateModuleId: undefined,
              templateModuleSectionId: undefined,
              downloadPdfPreferences: undefined,
            })
          }
          placeholder={t('form-builder:form-properties.form-type')}
        />
        {type !== undefined && type === FormType.Document && (
          <>
            <div className="text-dpm-12 mt-4 inline-block">{t('form-builder:form-properties.select-class')}</div>
            {originalForm?.templateModuleId !== templateModuleId && !!templateFormId && (
              <Tooltip text={t('form-builder:document-class-warning')}>
                {(tooltip) => (
                  <span {...tooltip} data-cy="invalid-state-icon" className="text-dpm-14 text-semantic-3 inline-block pl-1 font-medium">
                    ⚠
                  </span>
                )}
              </Tooltip>
            )}
            <DropdownSelect
              data-cy="module"
              options={moduleTemplateOptions}
              value={moduleTemplateOption}
              onChange={(option) => {
                patchFormConfig({
                  templateModuleId: option.id,
                  templateModuleSectionId: moduleTemplates.find((x) => x.id === option.id)?.sections[0].id,
                });
              }}
              placeholder={t('form-builder:form-properties.select-class')}
              tertiaryButtonTitle="Add new"
              onTertiaryButtonClick={() => setShowAddClassModal(true)}
              isFetching={loadingModules}
            />
          </>
        )}
      </Accordion>
      {type !== undefined && type !== FormType.Document && (
        <Accordion active title={<div className="font-medium">{t('form-builder:form-properties.configuration')}</div>}>
          <Checkbox
            labelBeforeCheckbox
            slider
            sliderSize={SliderSize.S}
            value={requiresApproval}
            onChange={(requiresApproval) => patchFormConfig({ requiresApproval })}
            label={t('form-builder:form-properties.requires-approval')}
            description={t('form-builder:form-properties.requires-approval-description')}
          />

          {requiresApproval && (
            <Checkbox
              className="my-4"
              labelBeforeCheckbox
              value={requiresApprovalSignature}
              onChange={(requiresApprovalSignature) => patchFormConfig({ requiresApprovalSignature })}
              label={t('form-builder:form-properties.requires-approval-signature')}
            />
          )}

          <div className="border-gray-6 my-4 w-full border-t" />

          <Checkbox
            labelBeforeCheckbox
            slider
            sliderSize={SliderSize.S}
            value={requiresValidation}
            onChange={(requiresValidation) => patchFormConfig({ requiresValidation })}
            label={t('form-builder:form-properties.requires-validation')}
            description={t('form-builder:form-properties.requires-validation-description')}
          />

          {requiresValidation && (
            <Checkbox
              className="my-4"
              labelBeforeCheckbox
              value={requiresValidationSignature}
              onChange={(requiresValidationSignature) => patchFormConfig({ requiresValidationSignature })}
              label={t('form-builder:form-properties.requires-validation-signature')}
            />
          )}

          <div className="border-gray-6 my-4 w-full border-t" />

          <Checkbox
            labelBeforeCheckbox
            slider
            sliderSize={SliderSize.S}
            disabled={!requiresValidation && !requiresApproval}
            value={requiresOwnerAcknowledgement}
            onChange={(requiresOwnerAcknowledgement) => patchFormConfig({ requiresOwnerAcknowledgement })}
            label={t('form-builder:form-properties.requires-owner-acknowledgement')}
            description={t('form-builder:form-properties.requires-owner-acknowledgement-description')}
          />
        </Accordion>
      )}
      {hasPublicSections && (
        <Accordion active title={<div className="font-medium">{t('form-builder:form-properties.public-form-heading')}</div>}>
          <TranslatableInput
            data-cy="public-form-title"
            label={t('form-builder:form-properties.public-title')}
            placeholder={t('form-builder:form-properties.public-title')}
            translationKey="publicTitle"
            translations={translations}
            onTranslationsChange={(translations) => patchFormConfig({ translations })}
          />

          <TranslatableInput
            data-cy="public-form-description"
            label={t('form-builder:form-properties.public-description')}
            placeholder={t('form-builder:form-properties.public-description')}
            translationKey="publicDescription"
            translations={translations}
            onTranslationsChange={(translations) => patchFormConfig({ translations })}
          />
        </Accordion>
      )}
      <Accordion active title={<div className="font-medium">{t('form-builder:form-properties.periodic-review')}</div>}>
        <div className="mt-2 flex items-center justify-between">
          <div>{t('periodic-review:toggle-checkbox')}</div>
          <div>
            <Checkbox value={showPeriodicReview} onChange={(value) => togglePeriodicReview(value)} slider sliderSize={SliderSize.S} />
          </div>
        </div>
        <div className="text-dpm-12">{t('periodic-review:description')}</div>
        {showPeriodicReview && (
          <PeriodicReviewContext.Provider value={{ config: periodicReviewConfig || EmptyPeriodicReviewConfig }}>
            <div className="flex flex-col gap-4 py-4">
              <ReviewGoal onChange={onPeriodicReviewChange} />
              <ReviewConfig onChange={onPeriodicReviewChange} />
              <div>
                <div className="pb-2">
                  <div>{t('periodic-review:checklist.title')}</div>
                  <div className="text-dpm-12">{t('periodic-review:checklist.description')}</div>
                </div>
                <div className="flex items-center justify-between">
                  <div>{t('periodic-review:checklist.toggle')}</div>
                  <div>
                    <Checkbox value={showPeriodicReviewCheckList} onChange={(value) => togglePeriodicReviewChecklist(value)} />
                  </div>
                </div>
                {showPeriodicReviewCheckList && <ReviewCheckList onChange={onPeriodicReviewChange} />}
              </div>
            </div>
          </PeriodicReviewContext.Provider>
        )}
        <ModalContext.Provider value={{ open: showRemoveReviewModal, modalWidth: 'w-2/5', onClose: () => setShowRemoveReviewModal(false) }}>
          <ConfirmationModal
            onConfirm={removePeriodicReview}
            onCancel={() => setShowRemoveReviewModal(false)}
            title={t('periodic-review:modals.delete.title')}
            description={t('periodic-review:modals.delete.description')}
            confirmText={t('periodic-review:modals.delete.buttons.confirm')}
          />
        </ModalContext.Provider>
        <ModalContext.Provider
          value={{ open: showRemoveReviewCheckListModal, modalWidth: 'w-2/5', onClose: () => setShowRemoveReviewCheckListModal(false) }}
        >
          <ConfirmationModal
            onConfirm={removePeriodicReviewCheckList}
            onCancel={() => setShowRemoveReviewCheckListModal(false)}
            title={t('periodic-review:modals.delete-all-checklist.title')}
            description={t('periodic-review:modals.delete-all-checklist.description')}
            confirmText={t('periodic-review:modals.delete-all-checklist.buttons.confirm')}
          />
        </ModalContext.Provider>
        <ModalContext.Provider
          value={{
            open: showAddClassModal,
            onClose: () => setShowAddClassModal(false),
            modalWidth: 'xl:w-[800px] w-3/5',
          }}
        >
          <ManageClassesWizard
            onCancel={() => setShowAddClassModal(false)}
            classes={[]}
            onCreated={onClassCreated}
            onUpdated={() => {
              //NOOP
            }}
            activeStep={activeManageWizardStep}
            clientUsers={clientUsers}
            onUserInvited={getUsers}
          />
        </ModalContext.Provider>
      </Accordion>
      {type !== undefined && type != FormType.Document && (
        <Accordion
          active
          title={
            <div className="flex flex-col">
              <div className="mb-2 font-medium">{t('form-builder:form-properties.download-pref')}</div>
              <span className="text-dpm-12">{t('form-builder:form-properties.download-pref-description')}</span>
            </div>
          }
        >
          <PdfPreferences preferences={downloadPdfPreferences} onPreferenceChange={onDownloadPdfPreferencesChange} />
        </Accordion>
      )}
    </div>
  );
};

export default FormProperties;
