import { useDraggable } from '@dnd-kit/core';
import { FC } from 'react';
import { BuilderDroppableTypes } from './FormBuilderTypes';
import DragHandleIcon from '../shared/icon/DragHandleIcon';

type BaseProps = {
  id: string;
  name: string;
  type: string;
};

type ActionTypeListItemProps = BaseProps;

export const ActionTypeListItem: FC<ActionTypeListItemProps> = (props) => {
  const { id, name, type } = props;

  const { isDragging, setNodeRef, listeners, attributes } = useDraggable({
    id,
    data: { type: BuilderDroppableTypes.Sidebar, item: { name, type } },
  });

  return (
    <div
      data-cy={`action-${type}`}
      className={`hover:bg-primary-2 border border-dashed p-2 hover:bg-opacity-10 ${
        isDragging ? 'border-primary-2 bg-primary-2 bg-opacity-10' : 'bg-gray-5 border-transparent'
      } flex cursor-grab items-center gap-2`}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    >
      <DragHandleIcon className="text-gray-2 h-4 w-4" />

      {name}
    </div>
  );
};
