import { FC, useMemo } from 'react';
import Button, { ButtonType } from '../shared/form-control/Button';
import Checkbox from '../shared/form-control/Checkbox';
import InfoIcon from '../shared/icon/InfoIcon';
import Tooltip from '../shared/Tooltip';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { SectionPropertiesProps } from './FormBuilderTypes';
import StepTriggerEditor from './StepTriggerEditor';
import { useTranslation } from 'react-i18next';
import Accordion from '../shared/accordion/Accordion';
import TranslatableInput from '../shared/form-control/TranslatableInput';
import LanguageUtils from '../../utils/LanguageUtils';

const noneOption = { id: 'none', text: '(none)', value: 'none' };

const FormSectionProperties: FC<SectionPropertiesProps> = (props) => {
  const { form, section, patchSection } = props;
  const { requiresApproval, triggerSteps = [], isDraft, translations = {} } = section;
  const [stepTriggers] = useAutoAnimate<HTMLDivElement>();

  const { t } = useTranslation('form-builder');

  const onStepTriggerUpdated = (index: number, stepId: string, condition: string) => {
    if (stepId === noneOption.id) {
      //remove trigger
      patchSection({
        triggerSteps: triggerSteps.filter((x, i) => i !== index),
      });
    } else {
      if (!triggerSteps.length) {
        patchSection({ triggerSteps: [...triggerSteps, { stepId: stepId, condition: condition }] });
      } else {
        patchSection({
          triggerSteps: triggerSteps.map((s, i) => {
            if (i === index) {
              return { ...s, stepId: stepId, condition: condition };
            } else {
              return s;
            }
          }),
        });
      }
    }
  };

  const availableSteps = useMemo(() => {
    const currentStepIndex = form.sections.findIndex((x) => x.id === section.id);
    const usedSteps = triggerSteps?.map((step) => step.stepId);
    return [
      noneOption,
      ...form.sections
        .slice(currentStepIndex + 1)
        .filter((section) => !usedSteps?.includes(section.id) && !section.isDraft)
        .map((section) => {
          return { id: section.id, text: LanguageUtils.getTranslation('title', section.translations), value: section.id };
        }),
    ];
  }, [form.sections, section, triggerSteps]);

  const addStep = () => {
    patchSection({ triggerSteps: [...triggerSteps, { stepId: availableSteps[1]?.id, condition: '' }] });
  };

  const getSelectedStepOption = (stepId?: string) => {
    const selected = form.sections.find((x) => x.id === stepId);
    if (selected) {
      return { id: selected.id, text: LanguageUtils.getTranslation('title', selected.translations), value: selected.id };
    }
    return noneOption;
  };

  const onIsDraftChange = (value: boolean) => {
    if (value && section.isPublic) {
      patchSection({ isDraft: value, isPublic: false });
    } else {
      patchSection({ isDraft: value });
    }
  };

  return (
    <div data-cy="section-properties">
      <Accordion
        active
        title={
          <div className="font-medium">
            {t('form-section-properties.heading', {
              title: LanguageUtils.getTranslation('title', section.translations) || t('form-section-properties.heading-fallback'),
            })}
          </div>
        }
      >
        <TranslatableInput
          data-cy="section-title"
          placeholder={t('form-section-properties.section-title')}
          label={t('form-section-properties.section-title')}
          autoFocus
          translationKey="title"
          translations={translations}
          onTranslationsChange={(translations) => patchSection({ translations })}
        />

        <div className="mt-4"></div>
        <Checkbox
          value={requiresApproval}
          onChange={(requiresApproval) => patchSection({ requiresApproval })}
          label={t('form-section-properties.requires-approval')}
        />
        <Tooltip text={<div className="max-w-lg">{t('form-section-properties.draft-help')}</div>}>
          {(tooltip) => (
            <div className="flex items-center gap-4">
              <Checkbox value={isDraft} onChange={onIsDraftChange} label={t('form-section-properties.draft')} />
              <div {...tooltip}>
                <InfoIcon className="h-6 w-6" />
              </div>
            </div>
          )}
        </Tooltip>
      </Accordion>
      <Accordion
        active
        title={
          <Tooltip text={<div className="max-w-lg">{t('form-section-properties.trigger-step-help')}</div>}>
            {(tooltip) => (
              <div className="flex items-center gap-4 font-medium">
                {t('form-section-properties.trigger-step-heading')}
                <div {...tooltip}>
                  <InfoIcon className="h-6 w-6" />
                </div>
              </div>
            )}
          </Tooltip>
        }
      >
        <div ref={stepTriggers}>
          {triggerSteps?.map((step, i) => (
            <StepTriggerEditor
              key={`trigger-${i}`}
              form={form}
              availableSteps={availableSteps}
              selectedStep={getSelectedStepOption(step.stepId)}
              stepTrigger={step}
              onConditionUpdated={(stepId, condition) => onStepTriggerUpdated(i, stepId, condition)}
              lockToSectionId={section.id}
            />
          ))}
        </div>
        {triggerSteps?.length === 0 && (
          <StepTriggerEditor
            form={form}
            availableSteps={availableSteps}
            selectedStep={getSelectedStepOption()}
            stepTrigger={{ stepId: noneOption.id, condition: '' }}
            onConditionUpdated={(stepId, condition) => onStepTriggerUpdated(0, stepId, condition)}
            lockToSectionId={section.id}
          />
        )}
        {availableSteps.length > 1 && triggerSteps?.length > 0 && (
          <Button data-cy="add-step" className="mt-4" type={ButtonType.PRIMARY} onClick={addStep}>
            {t('form-section-properties.trigger-add-step')}
          </Button>
        )}
      </Accordion>
      <Accordion
        active
        title={
          <Tooltip text={<div className="max-w-lg">{t('form-section-properties.public-section-help')}</div>}>
            {(tooltip) => (
              <div className="flex items-center gap-4 font-medium">
                {t('form-section-properties.public-section')}
                <div {...tooltip}>
                  <InfoIcon className="h-6 w-6" />
                </div>
              </div>
            )}
          </Tooltip>
        }
      >
        <div className="flex items-center gap-4">
          <Checkbox
            data-cy="public-section-checkbox"
            value={section.isPublic}
            label={t('form-section-properties.public-section')}
            onChange={(value) => patchSection({ isPublic: value })}
            disabled={section.isDraft}
          />
        </div>
      </Accordion>

      <Accordion active title={<div className="flex items-center gap-4 font-medium">{t('form-section-properties.output-section')}</div>}>
        <div className="flex items-center gap-4">
          <Checkbox
            data-cy="export-hidden-checkbox"
            value={section.isHiddenFromExport}
            label={t('form-section-properties.hidden-from-export')}
            onChange={(value) => patchSection({ isHiddenFromExport: value })}
            disabled={section.isDraft}
          />
        </div>
      </Accordion>
    </div>
  );
};

export default FormSectionProperties;
